import { merge } from 'lodash'
import themeSettings from '~/themes/virtshop/settings'

const overloadSettings = {
  components: {
    AddToCartModalItemAdded: {
      displayedProducts: 'recommended',
    },
    PageCatalog: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
    },
    PageCheckoutFinished: {
      googleSurveyEnabled: true,
    },
    PageCheckoutItems: {
      showItemsHeader: false,
      showEmptyCart: false,
    },
    PageHomepage: {
      showHomepageArticles: true,
      showHomepageIntimateAdvisory: true,
    },
    PageProductDetail: {
      badgeWidgetsInSidebar: {
        enableOn: ['desktop'],
      },
      showRecommendedCrossSell: true,
    },
    ProductBox: {
      showProductAvailability: true,
      contentOrder: {
        image: 0,
        name: 1,
        category: 2,
        price: 3,
        availability: 4,
        description: 5,
        variants: 6,
        colorVariants: 7,
      },
    },
    StarRating: {
      activeColor: '#004651',
      inactiveColor: '#dee2e6',
      borderColor: '#004651',
    },
    FooterBottom: {
      showSocialNetworkLinks: false,
    },
  },
  layouts: {
    LayoutBase: {
      footerBadgeWidgets: {
        enableOn: ['mobile', 'tablet'],
      },
    },
  },
}

export default merge(themeSettings, overloadSettings)
